import * as defaultData from "../DefaultData";

export const TY_L = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.CNProductTypeData,
      value: "TY-L",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: false,
      disabled: false,
      display: true,
      checked: false,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value:
        "液体植物提取物中的活性物质来自于本公司特有的控温封闭提取系统，其中大多数生物活性化合物被完全保留到产品中。本提取液100%从天然植物提取。",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "Major Constituents In Native Herbs",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "中国",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: [],
      disabled: false,
      display: true,
    },
    appearance: {
      type: "select",
      label: "Appearance",
      tips_value: "液体",
      options_value: defaultData. CNAppearanceOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    solvent: {
      type: "input",
      label: "Solvent",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    preservative: {
      type: "input",
      label: "Preservative",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    other_ingredients: {
      type: "input",
      label: "Other Ingredients",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "custom_field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    bioactives_level: {
      type: "two_column_input",
      label: "Bioactives Level",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ph: {
      type: "two_column_input",
      label: "Ph",
      tips_value: {
        specification: "3.5-7.5",
        test_method: "pH meter",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "two_column_input",
      label: "Total Heavy Metals",
      tips_value: {
        specification: "<20 ppm",
        test_method: "USP<233>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "two_column_input",
      label: "Arsenic",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "two_column_input",
      label: "Cadmium",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    lead: {
      type: "two_column_input",
      label: "Lead",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "two_column_input",
      label: "Mercury",
      tips_value: {
        specification: "<1.0 ppm",
        test_method: "USP<232>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "two_column_input",
      label: "Pesticides",
      tips_value: {
        specification: "符合USP<565>",
        test_method: "USP<565>",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    color: {
      type: "two_column_input",
      label: "Color",
      tips_value: {
        specification: "标准",
        test_method: "视觉",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    odor: {
      type: "two_column_input",
      label: "Odor",
      tips_value: {
        specification: "天然淡植物气味",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    taste: {
      type: "two_column_input",
      label: "Taste",
      tips_value: {
        specification: "符合标准",
        test_method: "感官评定",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "two_column_input",
      label: "Total Plate Count",
      tips_value: {
        specification: "<100 cfu/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "two_column_input",
      label: "Yeast And Mold",
      tips_value: {
        specification: "<100 cfu/g",
        test_method: "FDA-BAM",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "two_column_input",
      label: "Ecoli",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "two_column_input",
      label: "Salmonella",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "two_column_input",
      label: "Staph Aureus",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "two_column_input",
      label: "Total Coliforms",
      tips_value: {
        specification: "",
        test_method: "FDA-BAM ",
      },
      value: {
        specification: "",
        test_method: "",
      },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "two_column_custom_input",
      label: "custom_field",
      tips_value: {
        specification: "",
        test_method: "",
      },
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      display: true,
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: "未开封情况下${other_notes.shelflife_number}个月保质期。产品对热敏感。",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "避光，密封保存",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    is_show_note: {
      type: "input",
      label: "Is Show Note",
      tips_value: false,
      value: "",
      disabled: false,
      display: false,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
  rev: {
    revision: {
      type: "rev",
      label: "Revision",
      tips_value: "",
      value: [
        {
          approved_by: "",
          edited_by: "",
          is_complete: false,
          reason: "",
          requested_by: "",
          revised_notes: "",
          revision_date: "",
        },
      ],
      disabled: false,
      display: true,
    },
  },
};
