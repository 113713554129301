<template>
  <div style="position: relative">
    <el-form-item :label="labelText(zdItem.label)">
      <el-input
        type="textarea"
        :disabled="zdItem.disabled"
        v-model="zdFormattedValue"
        :placeholder="$t('view.placeholder')"
        clearable
        :autosize="{ minRows: 2, maxRows: 8 }"
        :style="textareaDefaultStyle"
        @change="handleChange()"
      ></el-input>
      <template v-if="zdItem.tips_value.length > 0">
        <el-popover
          placement="top-start"
          :content="getTipsValue()"
          trigger="hover"
          style="position: absolute; right: 10px; top: 10px"
        >
          <el-button
            slot="reference"
            icon="el-icon-edit"
            size="small"
            type="primary"
            :disabled="zdItem.disabled"
            @click="defaultValueHandle(zdItem.tips_value)"
            >Default</el-button
          >
        </el-popover>
      </template>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDTextarea",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      lock: false,
    };
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    
    zdFormattedValue: {
      get() {
        console.info('this.zdItem:',this.zdItem)
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        console.log("set:", value);
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
    textareaDefaultStyle() {
      return this.zdItem.tips_value
        ? "width: 100%; box-sizing: border-box; padding-right: 120px;"
        : "";
    },
  },
  created() {
    console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    setupValue(value) {
      console.log("1111setupValue:", this.zdItem.tips_value);
      let val = value;
      if(this.zdItem.tips_value.indexOf("${general_specification.common_other_name}")>-1){
        let commonOtherNameId = ["tab1_common_other_name_id_tag_1", "tab1_common_other_name_id_tag_2", "tab1_common_other_name_id_tag_3"].find(id => this.zdGetComponentState(id).value) || "";
        let commonOtherNameIdVal = this.zdGetComponentState(commonOtherNameId).value || "";
        val = value.replace("${general_specification.common_other_name}",commonOtherNameIdVal);
      }else{
        //获取已有值
        let botanicalNameId = ["tab1_botanical_name_id_tag_1", "tab1_botanical_name_id_tag_2", "tab1_botanical_name_id_tag_3"].find(id => this.zdGetComponentState(id).value) || "";
        let botanicalNameIdVal = this.zdGetComponentState(botanicalNameId).value || "";
        console.log("botanicalNameIdVal:", botanicalNameIdVal);
        val = value.replace("${general_specification.botanical_name}",botanicalNameIdVal);
      }
      console.log("2222val>>>>:", this.zdItem.value);
      
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: val,
      });
    },
    getTipsValue(){
      let tips_value = this.zdItem.tips_value;
        // 获取common_other_name
        // tips_value = tips_value.replace("${general_specification.common_other_name}",' ');
        // tips_value = tips_value.replace("${general_specification.botanical_name}",' ');

        console.info('this.zdItem.value=====:',this.zdItem.value)
        if(this.zdItem.tips_value.indexOf("${general_specification.common_other_name}")>-1){
          let commonOtherNameId = "tab1_common_other_name_id_tag_2";
          let commonOtherNameIdVal = this.zdGetComponentState(commonOtherNameId).value || "";
          console.log("area1----commonOtherNameIdVal:", commonOtherNameIdVal);
          tips_value = tips_value.replace("${general_specification.common_other_name}",commonOtherNameIdVal);
        }else{
          //获取已有值
          let botanicalNameId = ["tab1_botanical_name_id_tag_1", "tab1_botanical_name_id_tag_2", "tab1_botanical_name_id_tag_3"].find(id => this.zdGetComponentState(id).value) || "";
          let botanicalNameIdVal = this.zdGetComponentState(botanicalNameId).value || "";
          console.log("area2----botanicalNameIdVal:", botanicalNameIdVal);
          tips_value = tips_value.replace("${general_specification.botanical_name}", botanicalNameIdVal);
        }
        return tips_value;
    },
    defaultValueHandle(value) {
      this.setupValue(value);
    },
    handleChange() {
      
    },
  },
};
</script>
